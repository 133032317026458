import { Component, EventEmitter, OnInit, Output, OnDestroy, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, NgForm, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbDatepickerConfig, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { of, Subject, Subscription } from 'rxjs';
import { concatMap, takeUntil } from 'rxjs/operators';

import * as moment from 'moment';
import { Verify } from '../../models/user.model';
import { FormsService } from '../../services';
import { PlatformApiService } from '../../services/platform-api.service';
import { FormCanDeactivate } from 'src/app/authguard/form-can-deactivate';
import { SearchService } from 'src/app/eligibility-search/service/search.service';
import { AppConfig } from 'src/app/shared/services/app-config.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { Provider } from '../../models/provider.model';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';

@Component({
  selector: 'app-membership-verification',
  templateUrl: './membership-verification.component.html',
  styleUrls: ['./membership-verification.component.css']
})

export class MembershipVerificationComponent extends FormCanDeactivate implements OnInit, OnDestroy {
  @Output() nextEvent = new EventEmitter<number>();
  @ViewChild('formOne', { static: false }) form: NgForm;

  membershipForm: FormGroup;
  membershipServicesForm: FormGroup;
  showProductSetup: boolean;
  showVerifyButtonSpinner: boolean;
  verifyResult: any;
  verificationFailed: boolean;
  clicksCount = 0;
  verificationAttemptsExceeded: boolean;
  benefitsExpired: boolean;
  alreadyActive: boolean;
  noProgramAssigned: boolean;

  hasPacemaker = false;
  hasServiceAlready = false;
  hasAlzheimersOrDementia = false;
  hasMultipleDisqualifyingFactors = false;

  showChoices: boolean;
  careCard: string;
  carePhoneNo: string;
  careName: string;
  providerName: string;
  aliasname: string; careVerify: string;
  externalId: string; ext: string;

  memberVerifySubs: Subscription;
  zipcodeSubs: Subscription;
  dateModel: NgbDateStruct;
  isRepLoggedIn = false;
  captchaKey: string = this.appConfig.config.captcha;
  searchedDate: string;

  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private appConfig: AppConfig,
    private authService: AuthService,
    private dateConfig: NgbDatepickerConfig,
    private formsService: FormsService,
    private membershipFormBuilder: FormBuilder,
    private membershipServicesFormBuilder: FormBuilder,
    private membershipRoute: Router,
    private platformApiService: PlatformApiService,
    private searchService: SearchService
  ) {
    super();
    this.benefitsExpired = false;
    this.alreadyActive = false;
    this.dateConfig.minDate = {
      year: 1900,
      month: 1,
      day: 1
    };
    this.dateConfig.maxDate = this.dateToNgbDateStruct(new Date());
  }

  ngOnInit() {
    this.authService.isLoggedIn$().subscribe(isRepLoggedIn => {
      this.isRepLoggedIn = isRepLoggedIn;
    });

    this.benefitsExpired = false;
    this.formsService.clearForms();
    this.formsService.useDefaultLogo$.next(false);

    // Update fields based on selected provider
    this.formsService.careDetails$.pipe(takeUntil(this.destroy$)).subscribe((memberCareType: Provider) => {
      if (memberCareType) {
        this.careCard = memberCareType.cardimage;
        this.carePhoneNo = memberCareType.phoneno;
        if (this.carePhoneNo === undefined) {
          this.carePhoneNo = memberCareType.helpPhoneNumber;
        }
        this.ext = memberCareType.ext;
        this.careName = memberCareType.carename;
        this.providerName = memberCareType.name;
        if (this.careName === undefined) {
          this.careName = memberCareType.name;
        }
        this.aliasname = memberCareType.affiliateid;
        this.careVerify = this.aliasname ? this.aliasname.toLowerCase() : this.aliasname;
        this.externalId = memberCareType.externalid;
      }
    });

    let defaultLastName = '';
    let defaultFirstName = '';
    let defaultMemberId = '';
    let defaultZipCode = '';
    let defaultDateModel: NgbDateStruct = null;
    const selectedCustomer = this.searchService.getSelectedCustomer();

    if (selectedCustomer) {
      defaultLastName = selectedCustomer.lastName.toUpperCase();
      defaultFirstName = selectedCustomer.firstName.toUpperCase();
      defaultMemberId = selectedCustomer.memNbr.toUpperCase();
      defaultDateModel = this.stringToNgbDateStruct(selectedCustomer.mbrDob.toString());
      defaultZipCode = selectedCustomer.zip;
    }

    this.membershipForm = this.membershipFormBuilder.group({
      lastName: [defaultLastName, {
        validators: [Validators.required, Validators.pattern(/^([A-Za-z\-\']+ )+[A-Za-z\-\']+$|^[A-Za-z\-\']+$/),
        Validators.maxLength(40)]
      }],
      firstName: [defaultFirstName, {
        validators: [Validators.required, Validators.pattern(/^([A-Za-z\-\']+ )+[A-Za-z\-\']+$|^[A-Za-z\-\']+$/),
        Validators.maxLength(40)]
      }],
      memberId: [defaultMemberId, [Validators.required, Validators.pattern(/^[A-Za-z0-9]*$/)]],
      dob: [defaultDateModel, Validators.required],
      zipcode: [defaultZipCode, [Validators.required, Validators.pattern(/^[0-9]{5}$/)]],
      memberRecaptcha: ['', this.displayCaptcha() ? Validators.required : null]
    });

    this.membershipServicesForm = this.membershipServicesFormBuilder.group({
      hasPacemaker: [
        {
          value: 'no',
          disabled: this.isRepLoggedIn
        },
        this.isRepLoggedIn ? Validators.nullValidator : Validators.required
      ],
      hasServiceAlready: [
        {
          value: 'no',
          disabled: this.isRepLoggedIn
        },
        this.isRepLoggedIn ? Validators.nullValidator : Validators.required
      ],
      hasAlzheimersOrDementia: [
        {
          value: 'no',
          disabled: this.isRepLoggedIn
        },
        this.isRepLoggedIn ? Validators.nullValidator : Validators.required
      ]
    });

    this.showVerifyButtonSpinner = false;
    this.showChoices = true;
    if (!this.isRepLoggedIn) {
      const firstClickAt = new Date(localStorage.getItem(this.appConfig.config.localStorageFirstClickAt));
      const diffInSeconds = (Date.now() - firstClickAt.getTime()) / 1000;
      if (diffInSeconds < 7200) {
        this.verificationFailed = (parseInt(localStorage.getItem(this.appConfig.config.localStorageVerifyCount), 10) >= 5);
        this.verificationAttemptsExceeded = (parseInt(localStorage.getItem(this.appConfig.config.localStorageVerifyCount), 10) >= 5);
      } else {
        localStorage.clear();
      }
    }

    if (this.isRepLoggedIn) {
      this.membershipServicesForm.controls.hasPacemaker.setValue('no');
      this.membershipServicesForm.controls.hasServiceAlready.setValue('no');
      this.membershipServicesForm.controls.hasAlzheimersOrDementia.setValue('no');
    }
  }

  submitMemberForm() {
    this.showVerifyButtonSpinner = true;

    // Count of attempts for a user who is not a rep
    if (!this.isRepLoggedIn) {
      const getCount = parseInt(localStorage.getItem(this.appConfig.config.localStorageVerifyCount), 10);
      if (isNaN(getCount)) {
        const count = 0;
        localStorage.setItem(this.appConfig.config.localStorageVerifyCount, count.toString());
        localStorage.setItem(this.appConfig.config.localStorageFirstClickAt, (new Date()).toISOString());
      }
    }

    this.memberVerify();
  }

  memberVerify() {
    this.noProgramAssigned = false;
    this.benefitsExpired = false;
    this.alreadyActive = false;
    const monthStr = (this.membershipForm.controls.dob.value.month <= 9 ? '0' : '') + (this.membershipForm.controls.dob.value.month);
    const dayStr = (this.membershipForm.controls.dob.value.day <= 9 ? '0' : '') + (this.membershipForm.controls.dob.value.day);
    const dobStr = this.membershipForm.controls.dob.value.year + '-' + monthStr + '-' + dayStr;

    // Member Eligibility - External Lookup API
    this.memberVerifySubs = this.formsService.verifyMemberInformation(this.aliasname,
      this.externalId,
      this.membershipForm.controls.memberId.value,
      dobStr,
      this.membershipForm.controls.lastName.value,
      this.membershipForm.controls.firstName.value,
      this.membershipForm.controls.zipcode.value
    ).pipe(concatMap((verifyResponse: Verify) => {
      const memberControl = this.membershipForm.controls;
      const date = this.membershipForm.controls.dob.value;
      const formatDate = moment([date.year, date.month - 1, date.day]).format('YYYY-MM-DD');

      this.platformApiService.verificationData = verifyResponse;

      if (verifyResponse.users[0].programCode === 'na') {
        this.noProgramAssigned = true;
        this.verificationFail();
        return of();
      }

      // Check lastname and DOB is the same from response
      if ((memberControl.lastName.value.toLowerCase() === verifyResponse.users[0].lastName.toLowerCase()) &&
        (formatDate === verifyResponse.users[0].dateOfBirth)) {
        this.formsService.tokenInformation$.next({
          token: verifyResponse.accessToken,
          memberId: memberControl.memberId.value,
          code: verifyResponse.users[0].programCode,
          userId: verifyResponse.users[0].id,
          affiliateName: verifyResponse.affiliations.name,
          deviceType: verifyResponse.users[0].deviceType
        });

        const hasId = ('id' in verifyResponse.users[0]);
        if (!hasId) {
          this.verificationFail();
          return of();
        }
      } else {
        // If Validation fails in last name and DOB => Show Exit
        this.verificationFail();
        return of();
      }
      this.showProductSetup = true;
      return of();
    })).subscribe((resultResponse: HttpResponse<Verify>) => {
      if (resultResponse.status === 200) {
        this.showProductSetup = true;
      }
    }, (error: unknown) => {
      if (error instanceof HttpErrorResponse) {
        if (error.status === 404) {
          this.benefitsExpired = true;
        } else if (error.status === 422) {
          this.alreadyActive = true;
        }
      }
      this.verificationFail();
    });
  }

  verificationFail() {
    this.formsService.tokenInformation$.next({
      token: '',
      memberId: '',
      code: '',
      userId: '',
      affiliateName: '',
      deviceType: ''
    });
    this.showProductSetup = true;
    this.verificationFailed = true;
    this.showVerifyButtonSpinner = false;

    if (!this.isRepLoggedIn) {
      this.clicksCount = parseInt(localStorage.getItem(this.appConfig.config.localStorageVerifyCount), 10) + 1;
      localStorage.setItem(this.appConfig.config.localStorageVerifyCount, this.clicksCount.toString());
      this.verificationAttemptsExceeded = (this.clicksCount >= 5) ? true : false;
    }
  }

  showPrevious() {
    this.showProductSetup = false;
    this.verificationFailed = false;
  }

  /**
   * @method: eligibilityCareCheck()
   * @description: Fake eligibility check for both uhc and humana
   */
  eligibilityCareCheck() {
    this.formsService.verifyEligibility().subscribe(() => {
      this.showVerifyButtonSpinner = false;
      this.formsService.tokenInformation$.next({
        token: '',
        memberId: this.membershipForm.controls.memberId.value,
        code: '',
        userId: '',
        affiliateName: '',
        deviceType: ''
      });
      this.showProductSetup = true;
    }, () => {
      this.verificationFail();
    });
  }

  exitPage() {
    const providerRoute = this.membershipRoute.url.split('/')[1];
    this.formsService.clearForms();
    this.membershipRoute.navigateByUrl(`/${providerRoute}`);
  }

  goToFormMbrInfo() {
    if (!this.membershipServicesForm.valid && !this.isRepLoggedIn) {
      this.membershipServicesForm.markAllAsTouched();
    } else {
      this.hasPacemaker = (this.membershipServicesForm.controls.hasPacemaker.value === 'yes');
      this.hasServiceAlready = (this.membershipServicesForm.controls.hasServiceAlready.value === 'yes');
      this.hasAlzheimersOrDementia = (this.membershipServicesForm.controls.hasAlzheimersOrDementia.value === 'yes');

      const numberDisqualifyingFactors = [this.hasPacemaker, this.hasServiceAlready, this.hasAlzheimersOrDementia].filter(Boolean).length;
      this.hasMultipleDisqualifyingFactors = numberDisqualifyingFactors > 1;

      if (numberDisqualifyingFactors === 0) {
        this.formsService.formMemberVerification$.next(this.membershipForm);
        this.formsService.formVerifyServices$.next(this.membershipServicesForm);
        this.nextEvent.emit();
      }

      this.showChoices = false;
    }
  }

  backToChoice() {
    this.showChoices = true;
  }

  displayCaptcha() {
    let displayCaptcha: boolean;
    this.authService.isLoggedIn$().subscribe(isLoggedIn => displayCaptcha = !isLoggedIn);
    return displayCaptcha;
  }

  onCaptchaLoad() {
    if (this.appConfig.config.debug) {
      console.log('Google reCAPTCHA loaded');
    }
  }

  onCaptchaLoadError() {
    if (this.appConfig.config.debug) {
      console.log('Something went wrong when loading the Google reCAPTCHA');
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private stringToNgbDateStruct(dateString: string): NgbDateStruct {
    return this.dateToNgbDateStruct(new Date(dateString));
  }

  private dateToNgbDateStruct(date: Date): NgbDateStruct {
    return {
      year: date.getUTCFullYear(),
      month: date.getUTCMonth() + 1,
      day: date.getUTCDate()
    };
  }
}
